<template>
  <div class="uk-margin-top">
    <div class="uk-grid-small" uk-grid>
      <div class="uk-width-1-5"></div>
      <div class="uk-width-1-5"></div>
      <div class="uk-width-1-5"></div>
      <template v-if="jobPreferenceReorderMode && consist(user_cred.roles, ['qwe123','superadmin-product','superadmin-tech'])">
        <template v-if="cancelReorderJobPreferenceListButton || saveReorderJobPreferenceListButton">
          <div class="uk-width-1-5"></div>
          <div class="uk-width-1-5">
            <button
                type="button"
                class="uk-button uk-button-default uk-width-1-1"
                disabled
            >
              <span uk-spinner="ratio: 0.5"></span>
              <span class="uk-margin-small-left">Loading</span>
            </button>
          </div>
        </template>
        <template v-else>
          <div class="uk-width-1-5">
            <button
                type="button"
                class="uk-button uk-button-default uk-width-1-1"
                @click="cancelReorderJobPreferenceList"
            >Cancel</button>
          </div>
          <div class="uk-width-1-5">
            <button
                type="button"
                class="uk-button uk-width-1-1"
                :class="[onMoveJobPreferenceItem === true ? 'uk-button-default' : 'uk-button-primary']"
                :disabled="onMoveJobPreferenceItem === true"
                @click="saveReorderJobPreferenceList"
            >Save Changes</button>
          </div>
        </template>
      </template>
      <template v-else-if="!jobPreferenceReorderMode && consist(user_cred.roles, ['qwe123','superadmin-product','superadmin-tech'])">
        <div class="uk-width-1-5"></div>
        <div class="uk-width-1-5">
          <button
              type="button"
              class="uk-button uk-button-primary uk-width-1-1"
              @click="enableJobPreferenceReorderMode({ value: true })"
          >Reorder</button>
        </div>
      </template>
    </div>

    <div class="uk-card uk-card-default uk-margin-top">
      <div class="uk-overflow-auto">
        <table class="uk-table uk-table-middle uk-table-striped">
          <thead>
          <tr>
            <th class="uk-table-shrink">No</th>
            <th>Name</th>
            <th>Category</th>
            <th>Description</th>
            <th>Default</th>
            <th v-if="jobPreferenceReorderMode && consist(user_cred.roles, ['qwe123','superadmin-product','superadmin-tech'])" class="uk-width-small uk-text-center"></th>
            <th v-else-if="!jobPreferenceReorderMode && consist(user_cred.roles, ['qwe123','superadmin-product','superadmin-tech'])" class="uk-width-small uk-text-center">Action</th>
          </tr>
          </thead>
          <loading v-if="jobPreferenceListTableLoading" :colspan="3"></loading>
          <template v-else>
            <template v-if="jobPreferenceReorderMode && consist(user_cred.roles, ['qwe123','superadmin-product','superadmin-tech'])">
              <draggable
                  v-if="jobPreferenceData && jobPreferenceData.docs.length > 0"
                  v-model="jobPreferenceData.docs"
                  tag="tbody"
                  @change="onChange"
              >
                <tr
                    v-for="(jobPref, index) in jobPreferenceData.docs"
                    :key="index"
                    :draggable="jobPreferenceReorderMode"
                    :class="[jobPreferenceReorderMode === true ? 'box' : '']"
                >
                  <td class="uk-table-shrink">
                    {{ autoIncrementNumberPagination({
                    pagingCounter: jobPreferenceData.pagingCounter,
                    index
                  }) }}
                  </td>
                  <td>{{ jobPref.name || '-' }}</td>
                  <td>{{ setCategoryNameById(jobPref.job_preference_category_id) || '-' }}</td>
                  <td class="uk-text-truncate">{{ jobPref.description || '-' }}</td>
                  <td>{{ jobPref.default ? 'Yes' : 'No' }}</td>
                  <td class="uk-width-small uk-text-center">
                    <img :src="`${images}/arrow-bidirectional-up-down-12-filled.svg`" alt="Drag drop icon" class="drag-drop-icon">
                  </td>
                </tr>
              </draggable>
              <empty-table v-else :colspan="3" />
            </template>
            <template v-else-if="!jobPreferenceReorderMode && consist(user_cred.roles, ['qwe123','superadmin-product','superadmin-tech'])">
              <tbody v-if="jobPreferenceData && jobPreferenceData.docs.length > 0">
              <tr
                  v-for="(jobPref, index) in jobPreferenceData.docs"
                  :key="index"
                  :draggable="jobPreferenceReorderMode"
                  :class="[jobPreferenceReorderMode === true ? 'box' : '']"
              >
                <td class="uk-table-shrink">
                  {{ autoIncrementNumberPagination({
                  pagingCounter: jobPreferenceData.pagingCounter,
                  index
                }) }}
                </td>
                <td>{{ jobPref.name || '-' }}</td>
                <td>{{ setCategoryNameById(jobPref.job_preference_category_id) || '-' }}</td>
                <td class="uk-text-truncate">{{ jobPref.description || '-' }}</td>
                <td>{{ jobPref.default ? 'Yes' : 'No' }}</td>
                <td v-if="consist(user_cred.roles, ['qwe123','superadmin-product','superadmin-tech'])" class="uk-width-small uk-text-center">
                  <button
                      type="button"
                      class="uk-button uk-button-small uk-button-default"
                  >Actions</button>
                  <div uk-dropdown="mode: click">
                    <ul class="uk-nav uk-dropdown-nav uk-text-left">
                      <li><a href="#" @click="editJobPreference({ data: jobPref })">Edit</a></li>
                      <li class="uk-nav-divider"></li>
                      <li><a href="#" @click="deleteJobPreference({ data: jobPref })">Delete</a></li>
                      <li class="uk-nav-divider"></li>
                      <li><a href="#" @click="downloadTemplate({ data: jobPref })">Download Template</a></li>
                      <li class="uk-nav-divider"></li>
                      <li><a href="#" @click="ImportJobPreference({ data: jobPref })">Upload Data</a></li>
                    </ul>
                  </div>
                </td>
              </tr>
              </tbody>
              <empty-table v-else :colspan="3" />
            </template>
          </template>
        </table>
      </div>
      <pagination
          :total-data="jobPreferenceData && jobPreferenceData.totalDocs ? jobPreferenceData.totalDocs : 0"
          :change-limit="changeLimit"
          :change-page="changePage"
      ></pagination>
    </div>
  </div>
</template>

<script>
import { PREFIX_IMAGE } from '@/utils/constant';
import draggable from "vuedraggable";
import { getUserLogin } from '@/utils/auth';
import formatter from '@/utils/formatter';

export default {
    name: 'JobPreferenceTab',
    components: {
        draggable,
        Loading: () => import('@/components/globals/tables/Loading'),
        Pagination: () => import('@/components/globals/Pagination'),
        EmptyTable: () => import('@/components/globals/tables/EmptyTable')
    },
    props: {
        categoryOptions: {
            type: Array,
            required: false,
            default: () => []
        },
        jobPreferenceListTableLoading: {
            type: Boolean,
            required: false,
            default: false
        },
        cancelReorderJobPreferenceListButton: {
            type: Boolean,
            required: false,
            default: false
        },
        saveReorderJobPreferenceListButton: {
            type: Boolean,
            required: false,
            default: false
        },
        jobPreferenceReorderMode: {
            type: Boolean,
            required: false,
            default: false
        },
        jobPreferenceData: {
            type: Object,
            required: false,
            default() {
                return {
                    docs: [],
                    totalDocs: 0,
                    pagingCounter: 0,
                };
            }
        }
    },
    data() {
        return {
            user_cred: getUserLogin(),
            images: PREFIX_IMAGE,
            payloadReorderJobPreferenceList: {
                paging_counter: 0,
                job_preference_ids: []
            },
            onMoveJobPreferenceItem: true,
            meta: {
                limit: 10,
                page: 1,
                totalData: 0
            }
        };
    },
    methods: {
        setCategoryNameById(id){
            const item = this.categoryOptions.find(
                e => e._id === id
            );
            return item ? item.name : '-';
        },
        enableJobPreferenceReorderMode() {
            this.$emit('enable-job-preference-reorder-mode');
        },
        cancelReorderJobPreferenceList() {
            this.$emit('cancel-reorder-job-preference-list');
        },
        onChange(event) {
            this.onMoveJobPreferenceItem = false;

            if (event && event.moved) {
                this.resetPayloadReorderJobPreferenceList();
                this.fillPayloadReorderJobPreferenceList({ pagingCounter: this.jobPreferenceData.pagingCounter, jobPreferenceList: this.jobPreferenceData.docs });
            }
        },
        fillPayloadReorderJobPreferenceList({ pagingCounter, jobPreferenceList }) {
            this.payloadReorderJobPreferenceList.paging_counter = pagingCounter;
            if (jobPreferenceList && jobPreferenceList.length > 0) {
                const distinctJobPreferenceIds = [...new Set(jobPreferenceList.map(item => String(item._id)))];
                this.payloadReorderJobPreferenceList.job_preference_ids = distinctJobPreferenceIds;
            } else {
                this.payloadReorderJobPreferenceList.job_preference_ids = [];
            }
        },
        resetPayloadReorderJobPreferenceList() {
            this.payloadReorderJobPreferenceList.paging_counter = 0;
            this.payloadReorderJobPreferenceList.job_preference_ids = [];
        },
        hideDropdown() {
            const closeDropdown = window.UIkit.dropdown('.uk-dropdown', {
                delayHide: 0
            });
            closeDropdown ? closeDropdown.hide() : null;
        },
        saveReorderJobPreferenceList() {
            this.$emit('save-reorder-job-preference-list', this.payloadReorderJobPreferenceList);
        },
        editJobPreference({ data }) {
            const closeDropdown = window.UIkit.dropdown('.uk-dropdown', {
                delayHide: 0
            });
            closeDropdown ? closeDropdown.hide() : null;
            this.$emit('edit-job-preference', data);
        },
        deleteJobPreference({ data }) {
            const closeDropdown = window.UIkit.dropdown('.uk-dropdown', {
                delayHide: 0
            });
            closeDropdown ? closeDropdown.hide() : null;
            this.$emit('delete-job-preference', data);
        },
        autoIncrementNumberPagination({ pagingCounter, index }) {
            return formatter.autoIncrementNumberPagination({ pagingCounter, index });
        },
        ImportJobPreference({ data }) {
            const closeDropdown = window.UIkit.dropdown('.uk-dropdown', {
                delayHide: 0
            });
            closeDropdown ? closeDropdown.hide() : null;
            this.$emit('import-job-preference', data);
        },
        downloadTemplate({ data }) {
            const closeDropdown = window.UIkit.dropdown('.uk-dropdown', {
                delayHide: 0
            });
            closeDropdown ? closeDropdown.hide() : null;
            this.$emit('download-template-job-preference', data);
        },
        changeLimit(e) {
            this.meta = {
                ...this.meta,
                limit: e.target.value
            };
            this.$emit('change-limit', e);
        },
        changePage(value) {
            this.meta = {
                ...this.meta,
                page: value
            };
            this.$emit('change-page', value);
        },
        consist(user_roles, value){
            return value.some(i => user_roles.includes(i));
        }
    },
};
</script>

<style scoped>
.box {
  background: #FFFFFF;
  cursor: move;
}
.drag-drop-icon {
  width: 31px;
  height: 31px;
}
</style>
